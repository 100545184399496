<template>
  <div id="filter-actions" :style="{ 'flex-direction': `${direction}` }">
    <div v-if="applyEnabled" class="flex mr-4 text-warning">
      <feather-icon icon="AlertTriangleIcon" class="w-4 h-4 mr-2" />
      {{ lang.general.notApplied[languageSelected] }}
    </div>
    <div class="buttons mt-4">
      <vs-button @click="onApply" color="primary" :disabled="!applyEnabled">
        {{ lang.general.apply[languageSelected] }}
      </vs-button>
      <vs-button @click="onReset" type="flat" color="warning" class="ml-4">
        {{ lang.general.reset[languageSelected] }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FiltersActions',
  props: {
    onApply: {
      type: Function,
      required: true
    },
    applyEnabled: Boolean,
    onReset: {
      type: Function,
      required: true
    },
    direction: {
      type: String,
      default: 'row'
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>

<style lang="scss">
#filter-actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
